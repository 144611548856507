<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div
      class="d-flex flex-column flex-sm-row flex-wrap mt-n3 pt-3 pb-sm-3 toolbar"
    >
      <v-menu bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!$vuetify.breakpoint.xsOnly"
            v-show="!hasSelectedItem"
            large
            color="success"
            class="mb-4 mr-sm-4"
            :class="{ 'mr-md-auto': !hasOperation }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-plus-thick</v-icon>
            Nova NF-e
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <list dense :items="createNewItems" />
      </v-menu>

      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        fab
        fixed
        bottom
        right
        color="success"
        to="/notas-fiscais/produto/nova"
      >
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>

      <v-menu bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="hasOperation && !hasSelectedItem"
            large
            text
            outlined
            class="mb-4 mr-lg-auto mr-md-4 mr-sm-auto"
            v-bind="attrs"
            v-on="on"
          >
            Natureza de Operações
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <list
          dense
          :items="operations"
          @click:item="addOperationFilter"
          @click:clear="clearOperationFilter"
        />
      </v-menu>

      <v-btn
        v-show="hasSelectedItem"
        large
        text
        outlined
        class="mb-4 mr-md-auto mr-sm-4"
        :loading="exporting"
        :block="$vuetify.breakpoint.xsOnly"
        @click="bulkDownload"
      >
        <v-icon left color="primary">mdi-file-download-outline</v-icon>
        Exportar
      </v-btn>

      <month-picker-field
        class="mr-md-4 mb-4"
        :class="{ 'mr-sm-4': !hasOperation || hasSelectedItem }"
        v-model="monthFilter"
      />

      <div
        class="mb-4 flex-grow-1 flex-lg-grow-0"
        :class="{ 'flex-md-grow-0': !hasOperation || hasSelectedItem }"
      >
        <search-field
          clearable
          label="Cliente/Fornecedor ou Nota Fiscal"
          v-model="searchFilter"
        />
      </div>
    </div>

    <v-expand-transition>
      <v-row v-if="$vuetify.breakpoint.mdAndUp && !hasSelectedItem">
        <v-col cols="4">
          <nota-fiscal-list-chart
            color="green"
            title="Emitidas"
            footer-label="Última emissão"
            :footer="lastItemAuthorized"
            :loading="loadingChart"
            :disabled="loading"
            :month="monthFilter"
            :filter.sync="authorizedFilter"
            :value="itemsAuthorized"
          />
        </v-col>

        <v-col cols="4">
          <nota-fiscal-list-chart
            color="brown"
            title="Canceladas"
            footer-label="Último cancelamento"
            :footer="lastItemCanceled"
            :loading="loadingChart"
            :disabled="loading"
            :month="monthFilter"
            :filter.sync="canceledFilter"
            :value="itemsCanceled"
          />
        </v-col>

        <v-col cols="4">
          <nota-fiscal-list-chart
            color="orange"
            title="Pendentes"
            footer-label="Última alteração"
            :footer="lastItemPendingUpdatedAt"
            :loading="loadingChart"
            :disabled="loading"
            :month="monthFilter"
            :filter.sync="pendingFilter"
            :value="itemsPending"
          />
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-row>
      <v-col cols="12">
        <nota-fiscal-table
          show-operation
          :loading="loading"
          :search="searchFilter"
          :items="itemsFiltered"
          v-model="selectedItems"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DownloadFile from "@/mixins/DownloadFile";
import List from "@/components/List";
import MonthPickerField from "@/components/MonthPickerField";
import NotaFiscalListChart from "@/components/NotaFiscal/ListChart";
import NotaFiscalTable from "@/components/NotaFiscal/Table";
import SearchField from "@/components/SearchField";

export default {
  name: "NotasFiscaisProductList",
  metaInfo() {
    return {
      title: "Nota Fiscal de Produto",
    };
  },
  mixins: [DownloadFile],
  components: {
    List,
    MonthPickerField,
    SearchField,
    NotaFiscalListChart,
    NotaFiscalTable,
  },
  data: () => ({
    exporting: false,
    loadingChart: false,
    monthFilter: new Date().toISOString().substr(0, 7),
    searchFilter: "",
    operationFilter: [],
    pendingFilter: false,
    authorizedFilter: false,
    canceledFilter: false,
    selectedItems: [],
  }),
  computed: {
    createNewItems() {
      return [
        {
          title: "Venda",
          to: "/notas-fiscais/produto/nova?tipo=venda",
        },
        {
          title: "Compra",
          to: "/notas-fiscais/produto/nova?tipo=compra",
        },
        {
          title: "Estorno",
          to: "/notas-fiscais/produto/nova?tipo=estorno",
        },
        {
          title: "Devolução",
          to: "/notas-fiscais/produto/nova?tipo=devolucao",
        },
        {
          title: "Remessa",
          to: "/notas-fiscais/produto/nova?tipo=remessa",
        },
        {
          title: "Retorno",
          to: "/notas-fiscais/produto/nova?tipo=retorno",
        },
        {
          title: "Transferência",
          to: "/notas-fiscais/produto/nova?tipo=remessa&motivo=transferencia",
        },
      ];
    },
    itemsFiltered() {
      return this.itemsFilteredByStatus;
    },
    itemsFilteredByStatus() {
      let items = this.itemsFilteredByOperation;
      if (
        !(this.pendingFilter || this.authorizedFilter || this.canceledFilter)
      ) {
        return items;
      }
      return items.filter(({ status }) => {
        if (this.pendingFilter && status === "pending") {
          return true;
        }
        if (this.authorizedFilter && status === "authorized") {
          return true;
        }
        if (this.canceledFilter && status === "canceled") {
          return true;
        }
        return false;
      });
    },
    itemsFilteredByOperation() {
      if (this.operationFilter.length === 0) {
        return this.items;
      }
      return this.items.filter((item) =>
        this.operationFilter.includes(item.operationSlug)
      );
    },
    itemsPending() {
      return this.itemsFilteredByOperation.filter(
        ({ status }) => status === "pending"
      );
    },
    lastItemPendingUpdatedAt() {
      return this.itemsPending.reduce((res, { updatedAt }) => {
        updatedAt = this.$moment(updatedAt);
        if (!res || updatedAt.isAfter(res)) {
          return updatedAt.toDate();
        }
        return res;
      }, null);
    },
    itemsAuthorized() {
      return this.itemsFilteredByOperation.filter(
        ({ status }) => status === "authorized"
      );
    },
    lastItemAuthorized() {
      return this.itemsAuthorized.reduce((res, { authorized }) => {
        const authorizedAt = this.$moment(authorized.authorized_at);
        if (!res || authorizedAt.isAfter(res)) {
          return authorizedAt.toDate();
        }
        return res;
      }, null);
    },
    itemsCanceled() {
      return this.itemsFilteredByOperation.filter(
        ({ status }) => status === "canceled"
      );
    },
    lastItemCanceled() {
      return this.itemsCanceled.reduce((res, { canceled }) => {
        const canceledAt = this.$moment(canceled.canceled_at);
        if (!res || canceledAt.isAfter(res)) {
          return canceledAt.toDate();
        }
        return res;
      }, null);
    },
    items() {
      return this.nfes.map((item) => ({
        id: item.id,
        serie: item.serie,
        number: item.number,
        issueDate: item.issue_date,
        operationSlug: item.operation.slug,
        operationDescription: item.operation.description,
        recipient: item.recipient,
        total: item.totals.total,
        status: item.status,
        sefazId: item.sefaz_id,
        authorized: item.authorized,
        canceled: item.canceled,
        updatedAt: item.updated_at,
        viewHref: {
          name: "nf-product-view",
          params: { id: item.id },
        },
        xmlUrl: item.authorized
          ? `https://files.giox.com.br/nfe/${item.sefaz_id}.xml`
          : "",
        pdfUrl: item.authorized
          ? `https://files.giox.com.br/nfe/${item.sefaz_id}.pdf`
          : "",
      }));
    },
    operations() {
      let slugs = new Set(),
        descBySlug = new Map();

      this.items.map((item) => {
        const { operationSlug: slug, operationDescription: description } = item;
        slugs.add(slug);
        descBySlug.set(slug, description);
      });

      let ops = Array.from(slugs)
        .map((slug) => {
          const exists = this.operationFilter.indexOf(slug) !== -1;
          return {
            title: descBySlug.get(slug),
            value: slug,
            class: {
              "font-weight-bold": exists,
            },
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title));

      if (ops.length > 0 && this.operationFilter.length > 0) {
        ops.push({ divider: true });
        ops.push({
          title: "Remover filtro",
          value: "clear",
        });
      }
      return ops;
    },
    hasSelectedItem() {
      return this.selectedItems.length > 0;
    },
    hasOperation() {
      return this.operations.length > 0;
    },
    ...mapState("auth", ["company"]),
    ...mapState("notafiscal", ["loading"]),
    ...mapGetters("notafiscal", ["nfes"]),
  },
  watch: {
    company() {
      this.$nextTick().then(() => {
        // when a different company is selected, reset monthFilter
        this.monthFilter = new Date().toISOString().substr(0, 7);
        this.loadNFs();
      });
    },
    monthFilter() {
      this.loadNFs();
    },
    operationFilter() {
      this.updateCharts();
    },
  },
  mounted() {
    this.loadNFs();
  },
  methods: {
    async loadNFs() {
      this.selectedItems = [];
      this.$store.dispatch("notafiscal/fetch", {
        model: "nfe",
        month: this.monthFilter,
      });

      this.$nextTick().then(() => {
        this.updateCharts();
      });
    },
    updateCharts() {
      setTimeout(() => {
        this.loadingChart = true;
        setTimeout(() => {
          this.chartItems = this.itemsFiltered;
          this.$nextTick().then(() => {
            this.loadingChart = false;
          });
        }, 300);
      }, 100);
    },
    clearOperationFilter() {
      setTimeout(() => {
        this.operationFilter = [];
      }, 300);
    },
    addOperationFilter(v) {
      if (v === "clear") return;
      setTimeout(() => {
        const pos = this.operationFilter.indexOf(v);
        if (pos != -1) {
          this.operationFilter.splice(pos, 1);
        } else {
          this.operationFilter.push(v);
        }
      }, 300);
    },
    async bulkDownload() {
      if (!this.selectedItems.length) return;

      this.exporting = true;
      const pendings = this.selectedItems.filter(
        (item) => item.status === "pending"
      );
      if (pendings.length > 0) {
        this.notifyError("Somente NF-e autorizada pode ser exportada");
        this.exporting = false;
        return;
      }

      try {
        const { data } = await this.$http.post("/v1/nfes/download", {
          types: ["xml", "pdf"],
          nfes: this.selectedItems.map((item) => ({
            sefaz_id: item.sefazId,
          })),
        });
        const url = `/v1/nfes/download/${data.id}`;
        const filename = `${this.company.cnpj}-nfes.zip`;
        await this.downloadFileWhenReady(url, filename, data.id);
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao exportar as NF-es 😢");
      }
      this.exporting = false;
    },
  },
};
</script>

<style lang="sass">
.v-card--disabled
  > *:not(.v-card__progress)
    opacity: 0.4
</style>
